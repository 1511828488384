import axios from "../../../services/Api";

export default {
  methods: {
    close() {
      this.$emit("update:dialog", false);
    },
    getDataFromProduct() {
      if (this.transaction.external_id) {
        const product_id = this.orders.find(
          (x) => x.id == this.transaction.external_id
        )["order_lines"][0]["product_id"];
        axios.get("front-catalog/products/" + product_id).then((response) => {
          this.infoProduct = response.data;
        });
        return product_id;
      }
    },
  },
};
